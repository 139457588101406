@import "../../../../STYLES/variables";
.container{
    width:100%;
    // height: 40spx;
    /* position: fixed; */

    display: flex;
    justify-content: space-around;
}
 

.iconsContainer{
    width:130px;

    display: flex;
    justify-content: space-around;
}
.icon{
    background-color: white;
    width:40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: 0.2s;
}
.icon:hover{
    background-color: $main-color;
    transition: 0.2s;
}
.icon:hover > .svgIcon{
    filter: invert(1);
    transition: 0.2s;
}
.svgIcon{
    fill:black;
    transition: 0.2s;

}


@media screen and (max-width: 769px) {

}