@import "../../../STYLES/variables";

.button {
    cursor: pointer;

    border-radius: 8px;
    border: 1px solid $main-color;
    
    background-color: transparent;
    color: $main-color;
    /* color: blue; */

    padding: 0.25rem 1rem;
    margin:0.5rem;    
    width: 11rem;
    height: 3rem;

    font: inherit;
    font-size: 16px;
    font-weight: bold;

  }
  
  .button:hover,
  .button:active {
    background: $main-color;
    border-color: $main-color;
    color:white;
  }
  
  .button:focus {
    outline: none;
  }
  