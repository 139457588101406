.slideshowContainer{
    // padding: 10px;

    // width:auto !important;
    margin: 20px auto;
    max-width:1000px ;
    width:80%;
// background-color: red;
    // display: flex;       
    // justify-content:center;    
    // align-items: center;  
}
.pictureContainer{
    width: 95% !important;
    height:400px;

    // margin:5px !important;

    display: flex !important;       
    justify-content:center;    
    align-items: center;  

margin:0 !important;
padding: 0 !important;
}
.pic{
//   width: 60%;
object-fit:contain;
max-height: 100%;
max-width: 100%;
}