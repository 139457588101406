.navigation{    
    /* position:relative; */
    position: fixed;
    width: 100%;
    height:90px;
    /* margin-bottom: 100px; */
    
    /* box-shadow: 0px 0px 10px 2px #000000ad; */
    display:flex;
    /* justify-content:space-between; */
    justify-content:flex-end;
    align-items:center;
    z-index: 1000;
    background-color: white;
}

.navContainer{
    /* position:relative; */
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* background-color: yellowgreen; */
    /* background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) , white); */

    /* margin-right: 5rem; */
    width: 100%;    
    height:100%;
    /* padding:0 5%; */

    display:flex;    
    justify-content:space-between;
    /* justify-content:flex-end; */
    align-items:center;
}



/* .navigation.open ul{
    transition:.75s ease-in-out;
    visibility:visible;
    opacity:1;
} */

.backDiv{
    height:100%;      
    width: 90px; 
    /* background-color: white;        */
    display:none;
}


.showOnMobile{
    display:none;
}

.shadow{
    box-shadow: 0px 0px 10px 2px #000000ad;
}

 @media screen and (max-width: 769px) {    
    .showOnMobile{
        display:block;
    }    
    .navigation{    
        height:70px;
    }    
    .backDiv{     
        display:block;
        width: 100px;

    }    
}