
.textContainer{
    width:100%;
    height:auto; 
}
.title{
    width:100%;
    height: auto;
}
.itemsContainer{
    width:100%;
    height: auto;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}


.pictureContainer{
    width:100%;
    height:auto;
    /* height: 300px; */


    display: flex;
    justify-content: center;
}
.img {    
    width: 90%;
    max-width: 600px;
    height: auto;
    object-fit: contain;
    min-height: 100%;
    /* min-width: 25rem; */
}

.fillColor{
    fill:white;
}
@media screen and (max-width: 769px) {
    .mainContainer{
        flex-direction: column;

    }
    .title{
        text-align: center;
    }
    
}