@import "../../../../STYLES/variables";

.backdrop {
    position: fixed;
    top:90px;
    width: 100%;
    height: 100vh;
    
    z-index: 1001;

    background-color: rgba(255, 255, 255, 0.5);
    background:transparent;

    filter: blur(5px);
    backdrop-filter: blur(8px);
    box-sizing: border-box;
    animation: showUp 300ms ease-out forwards;
  }
  
.modal {
    position: fixed; 
    top: 70px;

    // background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) , white);    
    background-color: rgba(255, 255, 255, 1);
    padding: 1rem 0;

    box-shadow: 0px 10px 18px -5px #000000;
    z-index: 1002;
    animation: grow 300ms ease-out forwards;
    overflow: hidden;
}

.modal.active{
    top: 0px;    
}
  
  @media (min-width: 768px) {

  }
  
  @keyframes grow {
    from {
      opacity: 0;

      width:100%; 
      height: 0px;
    }
    to {
      opacity: 1;

      width:100%;
      height: 350px;
    }
  }

  @keyframes showUp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .ulNav{
    width:100%;
    height: auto;
    position:relative;
    margin:10px 0;
    padding: 0;
  }

  .liMobileNav{    
    list-style:none;
    margin:10px 0px;
    padding: 10px 0 10px 40px;
    width:100%;

    cursor:pointer;
    font-size: 20px;
    color:gray;
}
.liMobileNav:hover{ 
    background-color: $main-color;
    color:white;
}
