  

   .containerText{

        // width: 100%;
        display: flex;
        flex-direction: column;
       justify-content: center;
    }

    .containerAvantaje{
        // height: 80%;
        padding: 2rem 1rem;
// background-color: red;
        display: flex;
        flex-wrap: wrap;
        justify-content:space-evenly;   
        /* flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe; */
        /* align-items: center; */        
    }
     
@media screen and (max-width: 769px) {  
    .containerText{
        padding: 2rem 1rem;
    }
}