
.textContainer{
    display: flex;
    flex-direction: column;
    width:50%;
    max-width: 600px;
    height:auto;
    /* background-color: aqua; */
    margin: 2rem 0;
    
}
.buttons{
    width:100%;
    height:50px;
    /* background-color: rgb(0, 17, 255); */
    margin-top: 2rem;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}


.pictureContainer{

    width:50%;
    height:auto;
    margin:20px;
    display:flex;
    justify-content: center;
    
}
.img {    
    width: 100%;
    max-width: 500px;
    /* object-fit: cover; */
    /* min-width: 25rem; */
}

@media screen and (max-width: 769px) {
    .buttons{
        justify-content: center;
    }
    .textContainer{
        width:100%;
        text-align: center;
        margin: 0 2rem;
        margin-bottom: 2rem;
        order:1;
    }
    .textContainer p{
       margin:10px;

    }
    .pictureContainer{
        width:100%;
        margin:0;
        order:2;
    }
}