

.footerx  {
    /* background-color: rgba(236, 237, 255, 0.4); */
    background-color: rgb(0, 5, 92);
    /* rgb(0, 0, 105); */
}

.container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 25px;
    border-top: 1px solid #E4E4E4;
  margin: 0 2rem;
  }

  .container p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 130%;
    /* or 19px */
    
    letter-spacing: -0.02em; 
    color: #a1a1a1;
    
    
    opacity: 0.6;
  
  }
  
  .socialMediaContainer{
    width:100%;
    height:100px;
    background-color: rgb(0, 0, 105);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

  }

  @media screen and (max-width: 435px) {
    .container {
      flex-direction: column;
      align-items: center;
  }
  }

  @media screen and (max-width: 769px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .container p {
      text-align: center;
    }

}

  @media screen and (max-width: 1024px) {
    .container {
      padding:1em;
    }
}