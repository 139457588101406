
  .animation{         
     animation: itemAppear .2s ease-out forwards;           
  }

  @keyframes itemAppear {
      from {
        opacity: 0;
        /* transform: translateY(2rem); */
        transform: scale(50%);
      }
    
      to {
        opacity: 1;
        /* transform: translateY(0); */
        transform: scale(100%);
      }
    } 

    
  
  
  