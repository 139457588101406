$main-color: rgb(157, 42, 252);
$light-blue: #7984f6;
$btn-width: 120px;
$btn-height: 40px;
$block-padding: 60px;

// :root{
//     --brand-blue:#4353FF;
//     --brand-black:#333333;
//   --light-blue:#f4f5fc;
//   --main-grey:#666666;
//   --light-purple:#ECEDFF;
// }