@import "../../../../STYLES/variables";

.container{
    
    display: flex; 
    flex-direction: column;
     align-items: center;
    justify-content:space-between;
    height: 530px;
    /* width: 350px; */ 
    width:100%;
    max-width: 350px;
    text-align: center;
    margin:3rem 0.5rem;
    // background-color: rgb(230, 196, 196);
    border-radius: 20px;
    // box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.3);
   }
.pictureBox{
    height:250px;
    background-color: rgb(201, 125, 125);
    width: 100%;
    border-radius: 20px;
    
    
    /* display: flex; 
    align-items: center;
    justify-content: center; */

}
.dateContainer{
    width: 100%;
    height:30px;
    padding-top: 10px;
    
    display: flex; 
    

    & .dateLeft{
        width: 50%;
        height:100%;
        display: flex; 
        align-items: center;
        padding-left: 10px;
        & span {
            font-size: 15px;
        }

    }
    & .dateRight{
        width: 50%;
        height:100%;
        display: flex;
        align-items: center; 
        & span {
            font-size: 15px;
        }
    }
    & .svgIcon{
        width:18px;
        height:18px;
        margin-right: 5px;
        fill: gray;
        // fill: $main-color;
    }
    & span{
        color:gray;
    }

}

.readMore{
    color:$main-color;
    font-size: 18px;
    text-decoration:underline;

    font-weight: bold;
}
.h2{
    color:rgb(44, 44, 44);
}
.p{
    /* transform: translateY(-50px); */
}


.pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
 } 
 

@media screen and (max-width: 769px) {
    .dateContainer{
        & .svgIcon{
            width:16px;
            height:16px;
        }
        & .dateLeft{
            & span {
                font-size: 13px;
            }
        }
        & .dateRight{
            & span {
                font-size: 13px;
            }
        }
    }
}