  

.containerText{
    height: 20%;
    display: flex;
    flex-direction: column;
    /* padding: 6rem 1rem;
    padding-top: 3rem; */
}
.containerArticole{
    height: 80%;
    padding: 2rem 1rem;

    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;   
    /* flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe; */
    /* align-items: center; */        
}

 
@media screen and (max-width: 769px) {  
.containerText{
    padding: 0 1rem;

}
}