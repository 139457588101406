@import "../../../../STYLES/variables";

a {
    text-decoration: none;
}

.navListContainer{    
    width:auto;
    height:100%;     
    display:flex;
    justify-content:flex-end;
    align-items:center;
    /* margin-right: 5rem;     */

}
.ulMainNav{
    /* position:absolute; */
    padding:0px;
    
    display:flex;
    justify-content:flex-end;
    align-items:center;

    z-index:2;
    list-style:none;
    transition:0s;

    /* width:auto; */
    height:100%;    
    
}
.liMainNav{    
    /* padding:10px; */  
    margin:0px 10px;

    height:40%;
    // width:110px;
    width:auto;

    
    cursor:pointer;
    font-size:16px;
    /* background-color: red; */
    color:gray;
    // border-bottom: 2px solid transparent;
    /* float: left; display: block;  */
    display:flex;  
    justify-content:center;
    align-items:center;
    
    // background-color: red;
    position: relative;

    -webkit-tap-highlight-color: transparent; /* to prevent the Blue highlighting of elements in Chrome when clicking */
}
 
.liMainNav:hover span{    
    filter:drop-shadow(0px 3px 2px white); 
}


.liMainNav:hover, 
.liMainNav:active,
.liMainNav.active {
    // font-weight: bold;  
    color:$main-color;
 
    border-bottom: 2px solid $main-color;
    // box-shadow: 0px 0px 4px 2px #00000028; /* black shadow*/
   
  
   /* text-shadow: 0px 0px 1px white; */    
}


.navlinkContainer{
    display:flex;  
    justify-content:center;
    align-items:center;
    color:gray;
}



.hideTextOnMobile{
    /* visibility:visible; */
    /* opacity:1; */
    /* display:block; */
}

.langActiveColor{
    color:$main-color;
    font-weight: bold;
}


@media screen and (max-width: 769px) {
    .hideTextOnMobile{
        /* visibility:hidden; */
        /* opacity:0; */
        display:none;
    }
    .liMainNav:hover, 
    .liMainNav:active,
    .liMainNav.active {
    font-weight: bold;  
    color:black;
 
    border-bottom: none;
    box-shadow: none;
}
.liMainNav{    

    width:70px;
}

}