@import "../../../../../STYLES/variables";

.container{
    
    display: flex; 
    flex-direction: column;
     align-items: center;
    /* justify-content:space-between; */
    height: 280px;
    /* width: 350px; */
    width:100%;
    max-width: 250px;
    text-align: center;
    margin:3rem 0.5rem;
    padding:0 10px;
    border-radius: 10px;
    transition:.5s;
   }
   .container:hover{
        transform: translateY(-20px);
        transition:.5s;
   }
.pictureBox{
    position: relative;
    height:100px;
    min-height: 100px;
    background-color: white;
    width: 100px;
   
    border-radius: 20px;
    transform: translateY(-50px);
    
    // display: flex; 
    // align-items: center;
    // justify-content: center;

}
.h3{
    transform: translateY(-50px);
}
.p{
    transform: translateY(-50px);
}

.innerBox{
    position: absolute;
    width:55%;
    height:55%;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    margin: auto;
    // min-height: 50px;
    background-color: rgb(105, 69, 69);

   
    border-radius: 15px;
    transform: translateX(10px);
}
.svgIcon{
    position: absolute;
    width:50%;
    height:50%;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    margin: auto;
    // width:60px;  
    // fill:$main-color; 
    fill: rgb(51, 51, 51);
    /* filter:drop-shadow(0px 10px 5px rgba(0,0,0,0.5)); */
}

@media screen and (max-width: 769px) {
    .container{
        max-width: 390px;
        height: 260px;
    }
}

    