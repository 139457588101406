@import "../../../../STYLES/variables";
.mainContainer{
    width: 90%;
    max-width: 1200px;
    height: 300px;
    background-color: $main-color;
    border-radius: 30px;
    position: relative;
    }
.blueRectangle{
    position: absolute;
    width: 100%;
    height: 100px; 
   transform: translateY(250px);
    background-color: Rgb(0, 5, 92);
}
.topLeft{
    width: 20%;
    min-width: 200px;
    height: 30%;

    top:0;
    left:0;
    position: absolute;
}
.topRight{
    width: 20%;
    min-width: 200px;
    height: 30%;

   top:0;
   right:0;
   position: absolute;
    
}
.bottomLeft{
    width: 20%;
    height: 30%;

   bottom:0;
   position: absolute;
    
}
.textContainer{
    width: 60%;
    height: 60%;
    position: relative;
    top:60px;
    left:0;
    right:0;
    margin:auto;
    & h2{
        color:white;
    }
    
    }
    .inputContainer{
        width: 60%;
        height: 100px;
        background-color: rgb(255, 255, 255);
        position: relative;
        left:0;
        right:0;
        margin:auto;
        border-radius: 10px;
        
        }
.shape{
    //   width: 60%;
    object-fit:contain;
    max-height: 100%;
    max-width: 100%;
}

@media screen and (max-width: 769px) {
    .textContainer{
        padding-top: 100px;
        width:90%;
        text-align: center;
        padding-left: 0px;
    }
    .inputContainer{
        width: 90%;
        height: 50px;
    }
    .topRight{
        transform: translateX(10px);
    }
    .topLeft{
        transform: translateX(-50px);
    }
    .mainContainer{
        height: 600px;
    }
    .blueRectangle{
        height: 170px; 
    }
}
