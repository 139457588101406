
.logoContainer{
    width:20rem;
    height: 100%;
    /* position: fixed; */
    left:0px;
    top:0px;
    margin-left: 20px;
    -webkit-tap-highlight-color: transparent; /* to prevent the Blue highlighting of elements in Chrome when clicking */
 }
 
 
  .logo{
     background-image: url("../../../../FILES/Images/LogoParentsAdvisor.png"); 
     /* background-image: url("./Icons/lang10.gif");  */
     background-repeat:no-repeat; 
     /* background-position: top; */
     background-position: center;
     background-size: contain;
     background-origin: content-box;       
     width:100%;
     height: 100%;

     cursor:pointer;     
  }

  
@media screen and (max-width: 769px) {
   .logoContainer{
      margin-left: 10px;
      width:13rem;
   }
}