.buttonWithImg:hover,
.buttonWithImg:focus {
  /* border: 2px solid rgba(112, 76, 182, 0.4); */
 
}
.buttonWithImg :hover{
    border-radius: 20px;
}


/* .buttonWithImg:active {
  background-color: rgba(112, 76, 182, 0.2);
} */

.buttonWithImg{    
    /* border-radius: 10px; */
    margin:1rem;
    cursor:pointer;
    width:80px;

    /* background-image: url("../../../FILES/Images/AppStore.png"); */
    background-repeat: no-repeat;                
    /* background-color: white; */
    background-size: contain;    
    background-position: center;                

   /* max-width:50px; */
    position: relative;      /*/////////////////////////////*/       
    display: flex;           /*//Align text Horizontaly/////*/
    align-items: center;     /*/////////////////////////////*/
}                            /*/////////// +   /////////////*/
.buttonWithImg > .buttonDiv{ /*/////////////////////////////*/
    text-align:center;       /*/////////////////////////////*/
    left:0;                  /*//Align text Horizontaly/////*/
    right:0;                 /*/////////////////////////////*/
    position:absolute;       /*/////////////////////////////*/                
    color: white;
} 
.buttonWithImg > .buttonImg{
    vertical-align:middle;
    opacity:0.5;
    min-width: 100%;
    /* min-height: 100%; */
} 

@media screen and (max-width: 1000px) {
  .buttonWithImg{    
    width:160px;
  }
}
@media screen and (max-width: 769px) {
  .buttonWithImg{    
    width:150px;
  }
}