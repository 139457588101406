@import "../../../../../STYLES/variables";
.itemContainer{
    width:300px;
    height: 180px;

    margin:5px;
    display:flex;

    & .iconContainer{
        width:20%;
        height:60px;
        background-color:  rgba($main-color, 0.8);
        border-radius: 50%;
        transform: translateX(-10px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .textContainer{
        width:80%;
        height:100%;
    }
}


.svgIcon{

    width:35px;
    // height:40px;    
    // fill:gray; 
    /* filter:drop-shadow(0px 10px 5px rgba(0,0,0,0.5)); */
}