
.textContainer{
    display: flex;
    flex-direction: column;
    padding-left: 150px;
    width:50%;
    height:auto;
    /* background-color: aqua; */
    margin: 2rem 0;

}
.buttons{
    overflow: hidden;

    // margin: 2rem 4rem ;
    display:flex;
    align-items: center;
    justify-content: center;
    width:200px;
    -webkit-tap-highlight-color: transparent; /* to prevent the Blue highlighting of elements in Chrome when clicking */
}


.pictureContainer{
    width:50%;
    height:auto;
    /* height: 300px; */

    /* background-color: blueviolet; */
}
.img {    
    width: 100%;
    /* object-fit: cover; */
    /* min-width: 25rem; */
}
@media screen and (max-width: 1300px) {
    .textContainer{
        padding-left: 50px;
    }
}

@media screen and (max-width: 769px) {
    /* .mainContainer{
        flex-direction: column;

    } */
    .textContainer{
        width:96%;
        text-align: center;
        padding-left: 0px;
    }
    .pictureContainer{
        width:100%;
    }
    .buttons{
        margin: auto;
    }
}