.mainContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;

    left:0px;
    right:0px;
    margin: auto;
    background-color: rgb(252, 252, 252);
    & .topContainer{

        display:flex;
        flex-direction: row;
        & .titleContainer{
            width: calc(100% - 15px);
            padding-left: 15px;
            text-indent: 5vw;
            display: flex;
            align-items: center;

        }
        & .pictureContainer{
            width:100%;
            height:auto;
            /* height: 300px; */
        
            /* background-color: blueviolet; */
        }
    }
}

.textContainer{
    display: flex;
    flex-direction: column;
    // padding-left: 150px;
    width:90%;
    height:auto;
    /* background-color: aqua; */
    // margin: 2rem 0;
    text-align: left;
    padding-top: 30px;
    font-size: 20px;
    & p{
        margin:5px 0;
    }
}



.img {    
    width: 100%;
    /* object-fit: cover; */
    /* min-width: 25rem; */
}
@media screen and (max-width: 1000px) {
    .textContainer{
        // padding-left: 50px;
    }
    .titleContainer{
        // padding-left: 50px;
    }    
}

@media screen and (max-width: 769px) {
    .mainContainer{
        & .topContainer{
            display:flex;
            flex-direction: column;
        }
        & .titleContainer{
            text-indent:0; 
            font-size: 14px;
            text-align: center; 
            order:1; 
            width: 100%;
            padding-left:0;   
        }        
    }    
    .textContainer{
        text-align: left;
        // padding-left: 0px;
    }

}