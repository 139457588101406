.container{
    
    display: flex; 
    flex-direction: column;
     align-items: center;
    /* justify-content:space-between; */
    height: 270px;
    /* width: 350px; */
    width:100%;
    max-width: 300px;
    text-align: center;
    margin:2rem 0.5rem;
    background-color:rgb(168, 159, 159); 
    background-color:white; 
     border-radius: 10px;
     padding: 10px;
    padding-top: 20px;
    /* -webkit-box-shadow: 1px 1px 22px 9px rgba(150,150,150,0.1);  */
    box-shadow: 1px 1px 32px 9px rgba(170,170,170,0.1);
    transition:0.5s;
   }
.container:hover{
    transform: translateY(-20px);
    transition:0.5s;
}
.stepContainer{
    min-height:40px;
    background-color: rgb(230, 223, 223);
    color:rgb(59, 59, 59);
    width: 100px;
    border-radius: 20px;
    margin: 10px;
    display: flex; 
    align-items: center;
    justify-content: center;

}

.h2{
    margin: 30px 0 10px 0;
    font-weight: 600;
    color: rgb(39, 39, 39);
}

@media screen and (max-width: 769px) {
    .container{
        height: 270px;
    }
}

    