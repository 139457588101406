@import "../../../../STYLES/variables";

.dropMenu{
    cursor:pointer;
    position:fixed;
    right:0px;
    top:10px;
    width:70px;
    height:70px;
    border-radius:0 50% 50%;
    transform:rotate(45deg);
    transition:border-radius .5s;

    // box-shadow: 0px 0px 10px 2px #000000ad;
    // box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, -15px 5px 15px 32px rgba(0,0,0,0);

    z-index: 1010;
    -webkit-tap-highlight-color: transparent; /* to prevent the Blue highlighting of elements in Chrome when clicking */
}

.dropMenu.active{
    border-radius:50%;
    animation:drop .3s forwards;    
}



.containerMenuBars{
    transform:rotate(-45deg);
    height:100%;
    width:100%;
    
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.containerMenuBars span{
    display:block;
    background-color:rgb(41, 41, 41);
    background-color:$main-color;
    width:40px;
    height:3px;
    margin:5px 0;
    transition:.5s;
}

.containerMenuBars span.open:nth-child(1){
    transform:rotate(45deg) translate(9px, 9px);
}
.containerMenuBars span.open:nth-child(2){
    width: 0%; opacity:0;  
}
.containerMenuBars span.open:nth-child(3){
    transform:rotate(-45deg) translate(9px, -9px);
}



@keyframes drop{
    0%{
        transform:translateY(0) rotate(45deg);
    }
    50%{
        transform:translateY(30px) rotate(45deg);
    }
    100%{
        transform:translateY(10px) rotate(45deg);
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 10px 2px #000000ad;
    }
}




.showOnMobile{
    display:none;
}


@media screen and (max-width: 769px) {
    .dropMenu{
        top:0px;
        right:0px;
    }
    .showOnMobile{
        display:block;
    }
}