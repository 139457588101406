  

   .containerText{
    // height: 30px;
    display: flex;
    flex-direction: column;
    // margin: 6rem 1rem;
    // margin-top: 3rem;
    
}

.containerPas{
    width: 1200px;
    margin: auto;
    height: 80%;
    padding: 2rem 1rem;
    background-image: url("../../../../FILES/Images/dots.png");
    background-repeat: no-repeat; 
    background-position: center;
    // background-color: white;
    // background-size: cover;

    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;   
    /* flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe; */
    /* align-items: center; */        
}
 
@media screen and (max-width: 769px) {  
    .containerText{
        padding: 0 1rem;
    }
}
@media screen and (max-width: 1200px) {  
    .containerPas{
        // background-image: none;
        width: auto;
    }
}
@media screen and (max-width: 1070px) {  
    .containerPas{
        background-image: none;
        width: auto;
    }
}