.section {
   
}

.section h1{
    font-size: 45px;
    /* font-weight: bold; */
    color: #000;
    letter-spacing: -0.02em; 
}

.section h3{
    font-size: 20px;
    font-weight: bold;
    /* color: rgb(125, 125, 125);     */
    font-family: sans-serif;
}

  .section p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px; 
    line-height: 130%;
    
    letter-spacing: -0.02em; 
    color: #000000;
    opacity: 0.6;
    margin-top: 1rem;
  }

  .mainContainer {    
    /* margin: 0 5%;     */
    padding: 2rem 0;


    /* padding-bottom: 30px;
    padding-top: 125px;
    padding: 3rem 0;    */

    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    text-align: center;
    
  }


/* @media screen and (max-width: 435px) {
   
} */

@media screen and (max-width: 769px) {
    .mainContainer {
        flex-direction: column;
        align-items: center;
        margin: 0;    
        padding: 2rem 0;        
    }

    
    /* .mainContainer img {
        margin-top: 0px;
    } */
    .mainContainer h3{
        font-size: 16px;
    }  
     
    .mainContainer h1{
        font-size: 33px;
    }
    /* 
    .mainContainer p {
        font-size: 18px;
    }     */
}
@media screen and (max-width: 910px) {

}

  @media screen and (max-width: 1024px) {

}